import { css } from 'styled-components';

/* NOTE: These declrations are for gatsby apps consuming stile-shared.
  You must edit .storybook/preview-head.html and put fonts in /static/fonts/ *in each repo*
  to modify the fonts rendered in stile-shared Storybook.
  Keep changes to those and these in sync!
*/
export const storybook = css`
  .sb-show-main.sb-main-padded {
    padding: 0;
  }
`;
