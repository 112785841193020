import { css } from 'styled-components';

/* NOTE: These declrations are for gatsby apps consuming stile-shared.
  You must edit .storybook/preview-head.html and put fonts in /static/fonts/ *in each repo*
  to modify the fonts rendered in stile-shared Storybook.
  Keep changes to those and these in sync!
*/
export const fonts = css`
  @font-face {
    font-family: 'Boing';
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/Boing-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Boing';
    font-weight: 500;
    font-display: swap;
    src: url('../../fonts/Boing-Medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/Graphik-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: 400;
    font-style: italic;
    font-display: swap;
    src: url('../../fonts/Graphik-RegularItalic.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: 500;
    font-display: swap;
    src: url('../../fonts/Graphik-Medium.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Graphik';
    font-weight: 600;
    font-display: swap;
    src: url('../../fonts/Graphik-Semibold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'More Sugar';
    font-weight: 400;
    font-display: swap;
    src: url('../../fonts/More Sugar.woff2') format('woff2');
  }
`;
